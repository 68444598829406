import {
  BaseService,
  IEquipment,
  IEquipmentCondition,
  IEquipmentList,
  IEquipmentShort,
  requestWrapper,
  TRequestParamsId,
} from "shared"
import { AxiosResponse } from "axios"

class EquipmentService extends BaseService {
  getEquipmentList = ({
    params: { reqString },
  }: {
    params: { reqString: string }
  }): Promise<AxiosResponse<IEquipmentList>> => {
    return this.api.get(`/equipment/equipment/${reqString}`)
  }

  getEquipment = ({ params: { id } }: { params: { id: TRequestParamsId } }): Promise<AxiosResponse<IEquipment>> => {
    return this.api.get<IEquipment>(`/equipment/equipment/${id}/`)
  }

  getEquipmentConditionList = (): Promise<AxiosResponse<IEquipmentCondition[]>> => {
    return this.api.get(`/equipment/equipment/condition/`)
  }

  getEquipmentShortList = (): Promise<AxiosResponse<IEquipmentShort[]>> => {
    return this.api.get<IEquipmentShort[]>("/equipment/equipment/short/")
  }
}

const equipmentService = new EquipmentService()

export const equipmentAPI = {
  getEquipmentList: requestWrapper<IEquipmentList, typeof equipmentService.getEquipmentList>(
    equipmentService.getEquipmentList
  ),
  getEquipment: requestWrapper<IEquipment, typeof equipmentService.getEquipment>(equipmentService.getEquipment),

  getEquipmentConditionList: requestWrapper<IEquipmentCondition[], typeof equipmentService.getEquipmentConditionList>(
    equipmentService.getEquipmentConditionList
  ),

  getEquipmentShortList: requestWrapper<IEquipmentShort[], typeof equipmentService.getEquipmentShortList>(
    equipmentService.getEquipmentShortList
  ),
}
