import { AxiosResponse } from "axios"
import { BaseService, IReceivedParams, requestWrapper, TRequestParamsId } from "shared"

import { IEquipmentIndicatorsList, IExtendedIndicators, IIndicatorsList, TIndicatorShort } from "@types"

class IndicatorServices extends BaseService {
  getIndicatorsList = ({
    params: { reqString },
  }: IReceivedParams<{ reqString: string }>): Promise<AxiosResponse<IIndicatorsList>> => {
    return this.api.get<IIndicatorsList>(`/external/node/${reqString}`)
  }

  getEquipmentIndicatorsList = ({
    params: { id, reqString },
  }: IReceivedParams<{ id: TRequestParamsId; reqString: string }>): Promise<
    AxiosResponse<IEquipmentIndicatorsList>
  > => {
    return this.api.get<IEquipmentIndicatorsList>(`/external/node/${id}/${reqString}`)
  }

  patchMultipleEquipmentIndicators = ({
    params: { id },
    payload,
  }: IReceivedParams<{ id: TRequestParamsId }, TIndicatorShort>): Promise<AxiosResponse<IExtendedIndicators[]>> => {
    return this.api({
      method: "patch",
      url: `/external/node/${id}/multiple/`,
      data: payload,
      transformRequest: [
        function (data, headers): string {
          if (headers) headers["Content-Type"] = "application/json"

          return JSON.stringify(data)
        },
      ],
    })
  }
}

const indicatorService = new IndicatorServices()

export const indicatorServiceAPI = {
  getIndicatorsList: requestWrapper<IIndicatorsList, typeof indicatorService.getIndicatorsList>(
    indicatorService.getIndicatorsList
  ),
  getEquipmentIndicatorsList: requestWrapper<
    IEquipmentIndicatorsList,
    typeof indicatorService.getEquipmentIndicatorsList
  >(indicatorService.getEquipmentIndicatorsList),
  patchMultipleEquipmentIndicators: requestWrapper<
    IExtendedIndicators[],
    typeof indicatorService.patchMultipleEquipmentIndicators
  >(indicatorService.patchMultipleEquipmentIndicators),
}
