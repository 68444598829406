import {
  BaseService,
  IEquipmentOperatingTime,
  IEquipmentOperatingTimeList,
  IEquipmentOperatingTimePatch,
  IReceivedParams,
  requestWrapper,
  TRequestParamsId,
} from "shared"
import { AxiosResponse } from "axios"

class OperatingTimeService extends BaseService {
  getEquipmentOperatingTimeList = ({
    params: { reqString },
  }: IReceivedParams<{ reqString: string }>): Promise<AxiosResponse<IEquipmentOperatingTimeList>> => {
    return this.api.get(`/equipment/operation_time/${reqString}`)
  }

  getEquipmentOperatingTime = ({
    params: { equipmentId },
  }: IReceivedParams<{ equipmentId: TRequestParamsId }>): Promise<AxiosResponse<IEquipmentOperatingTime>> => {
    return this.api.get(`/equipment/operation_time/${equipmentId}/`)
  }

  postEquipmentOperatingTime = ({
    payload,
  }: IReceivedParams<undefined, Partial<IEquipmentOperatingTime>>): Promise<
    AxiosResponse<Partial<IEquipmentOperatingTime>>
  > => {
    return this.api.post(`/equipment/operation_time/`, payload)
  }

  patchEquipmentOperatingTime = ({
    params: { equipmentId },
    payload,
  }: IReceivedParams<{ equipmentId: TRequestParamsId }, IEquipmentOperatingTimePatch>): Promise<
    AxiosResponse<IEquipmentOperatingTimePatch>
  > => {
    return this.api.patch(`/equipment/operation_time/${equipmentId}/`, payload)
  }

  deleteEquipmentOperatingTime = ({
    params: { equipmentId },
  }: IReceivedParams<{ equipmentId: TRequestParamsId }>): Promise<AxiosResponse> => {
    return this.api.delete(`/equipment/operation_time/${equipmentId}/`)
  }
}

const operatingTimeService = new OperatingTimeService()

export const operatingTimeAPI = {
  getEquipmentOperatingTimeList: requestWrapper<
    IEquipmentOperatingTimeList,
    typeof operatingTimeService.getEquipmentOperatingTimeList
  >(operatingTimeService.getEquipmentOperatingTimeList),

  getEquipmentOperatingTime: requestWrapper<
    IEquipmentOperatingTime,
    typeof operatingTimeService.getEquipmentOperatingTime
  >(operatingTimeService.getEquipmentOperatingTime),

  postEquipmentOperatingTime: requestWrapper<
    Partial<IEquipmentOperatingTime>,
    typeof operatingTimeService.postEquipmentOperatingTime
  >(operatingTimeService.postEquipmentOperatingTime),

  patchEquipmentOperatingTime: requestWrapper<
    IEquipmentOperatingTimePatch,
    typeof operatingTimeService.patchEquipmentOperatingTime
  >(operatingTimeService.patchEquipmentOperatingTime),

  deleteEquipmentOperatingTime: requestWrapper<undefined, typeof operatingTimeService.deleteEquipmentOperatingTime>(
    operatingTimeService.deleteEquipmentOperatingTime
  ),
}
