import {
  BaseService,
  IEquipment,
  IEquipmentProcessingForm,
  IReceivedParams,
  requestWrapper,
  TRequestParamsId,
} from "shared"
import { AxiosResponse } from "axios"

type TPatchEquipment = IReceivedParams<{ equipmentId: TRequestParamsId }, IEquipmentProcessingForm>

class EquipmentProcessingService extends BaseService {
  patchEquipment = ({ params: { equipmentId }, payload }: TPatchEquipment): Promise<AxiosResponse<IEquipment>> => {
    return this.api.patch(`/equipment/equipment/${equipmentId}/`, payload)
  }
}

const equipmentProcessingService = new EquipmentProcessingService()

export const equipmentProcessingAPI = {
  patchEquipment: requestWrapper<IEquipment, typeof equipmentProcessingService.patchEquipment>(
    equipmentProcessingService.patchEquipment
  ),
}
