import { AxiosResponse } from "axios"
import {
  BaseService,
  IExportTaskItem,
  IExportTaskJournalPayload,
  IExportTaskList,
  IExportTaskRecommendationPayload,
  IPostExportDataChart,
  IReceivedParams,
  requestWrapper,
  TRequestParamsId,
} from "shared"

class ExportTaskService extends BaseService {
  getExportTaskItem = ({
    params: taskId,
  }: IReceivedParams<{ taskId: TRequestParamsId }>): Promise<AxiosResponse<IExportTaskItem>> => {
    return this.api.get(`/export/task/${taskId}/`)
  }

  getExportTaskList = ({
    params: { reqString },
  }: IReceivedParams<{ reqString: string }>): Promise<AxiosResponse<IExportTaskList>> => {
    return this.api.get<IExportTaskList>(`/export/task/${reqString}`)
  }

  postExportTaskDataChart = ({
    payload,
  }: IReceivedParams<undefined, IPostExportDataChart>): Promise<AxiosResponse<IPostExportDataChart>> => {
    return this.api({
      method: "post",
      url: "/export/task/data_chart/",
      data: payload,
      transformRequest: [
        function (data, headers): string {
          if (headers) headers["Content-Type"] = "application/json"
          return JSON.stringify(data)
        },
      ],
    })
  }

  postExportTaskJournal = ({
    payload,
  }: IReceivedParams<undefined, IExportTaskJournalPayload>): Promise<AxiosResponse<IExportTaskJournalPayload>> => {
    return this.api.post("/export/task/journal/", payload)
  }

  postExportTaskRecommendation = ({
    payload,
  }: IReceivedParams<undefined, IExportTaskRecommendationPayload>): Promise<
    AxiosResponse<IExportTaskRecommendationPayload>
  > => {
    return this.api.post("/export/task/recommendation/", payload)
  }

  deleteExportTaskRecommendation = ({
    params: taskId,
  }: IReceivedParams<{ taskId: TRequestParamsId }>): Promise<AxiosResponse<IExportTaskItem>> => {
    return this.api.delete(`/export/task/${taskId}/`)
  }
}

const exportTaskService = new ExportTaskService()

export const exportTaskAPI = {
  getExportTaskItem: requestWrapper<IExportTaskItem, typeof exportTaskService.getExportTaskItem>(
    exportTaskService.getExportTaskItem
  ),
  getExportTaskList: requestWrapper<IExportTaskList, typeof exportTaskService.getExportTaskList>(
    exportTaskService.getExportTaskList
  ),
  postExportTaskDataChart: requestWrapper<IPostExportDataChart, typeof exportTaskService.postExportTaskDataChart>(
    exportTaskService.postExportTaskDataChart
  ),
  postExportTaskJournal: requestWrapper<IExportTaskJournalPayload, typeof exportTaskService.postExportTaskJournal>(
    exportTaskService.postExportTaskJournal
  ),
  postExportTaskRecommendation: requestWrapper<
    IExportTaskRecommendationPayload,
    typeof exportTaskService.postExportTaskRecommendation
  >(exportTaskService.postExportTaskRecommendation),
  deleteExportTaskRecommendation: requestWrapper<
    IExportTaskItem,
    typeof exportTaskService.deleteExportTaskRecommendation
  >(exportTaskService.deleteExportTaskRecommendation),
}
