import { BaseService, IEchoModule, IEchoModuleList, requestWrapper, TRequestParamsId } from "shared"
import { AxiosResponse } from "axios"

class EchoModuleService extends BaseService {
  getEchoModule = ({
    params: { moduleId },
  }: {
    params: { moduleId: TRequestParamsId }
  }): Promise<AxiosResponse<IEchoModule>> => {
    return this.api.get(`/modules/echo/${moduleId}/`)
  }

  getEchoModuleList = ({
    params: { reqString },
  }: {
    params: { reqString: string }
  }): Promise<AxiosResponse<IEchoModuleList>> => {
    return this.api.get(`/modules/echo/${reqString}`)
  }

  patchEchoModule({
    moduleId,
    data,
  }: {
    moduleId: TRequestParamsId
    data: Partial<IEchoModule>
  }): Promise<AxiosResponse<IEchoModule>> {
    return this.api.patch(`/modules/echo/${moduleId}/`, data)
  }
}

const echoModuleService = new EchoModuleService()

export const echoModuleAPI = {
  getEchoModuleList: requestWrapper<IEchoModuleList, typeof echoModuleService.getEchoModuleList>(
    echoModuleService.getEchoModuleList
  ),
  getEchoModule: requestWrapper<IEchoModule, typeof echoModuleService.getEchoModule>(echoModuleService.getEchoModule),
  patchEchoModule: requestWrapper<IEchoModule, typeof echoModuleService.patchEchoModule>(
    echoModuleService.patchEchoModule
  ),
}
