import { AxiosResponse } from "axios"
import { BaseService, requestWrapper } from "shared"

import { IJournalEventList } from "@types"

class EventJournalService extends BaseService {
  getJournalEventList = ({
    params: { reqString },
  }: {
    params: { reqString: string }
  }): Promise<AxiosResponse<IJournalEventList>> => {
    return this.api.get<IJournalEventList>(`/journal/common/${reqString}`)
  }
}

const eventJournalService = new EventJournalService()

export const eventJournalAPI = {
  getJournalEventList: requestWrapper<IJournalEventList, typeof eventJournalService.getJournalEventList>(
    eventJournalService.getJournalEventList
  ),
}
