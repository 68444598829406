import React, { FC } from "react"
import { HashRouter } from "react-router-dom"
import { CssBaseline, ThemeProvider } from "@mui/material"
import { theme } from "shared"

import { WSContextProvider, LocalizationContextProvider } from "@context"
import { Head, RoutesComponent } from "@components"

/**
 * @returns {FC} - FC
 */
const App: FC = () => {
  return (
    <HashRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <WSContextProvider>
          <LocalizationContextProvider>
            <Head />
            <RoutesComponent />
          </LocalizationContextProvider>
        </WSContextProvider>
      </ThemeProvider>
    </HashRouter>
  )
}

export default App
